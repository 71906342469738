export const headCells = [
	{
		id: 'id',
	},
	{
		id: 'code',
		label: 'staffing_table.code_wp',
	},
	{
		id: 'position_name',
		label: 'staffing_table.position_name',
	},
	{
		id: 'tariff_rate',
		label: 'workplace.tariff_rate',
	},
	{
		id: 'salary',
		label: 'staffing_table.salary',
	},
	{
		id: 'supplement_surcharge',
		label: 'staffing_table.supplement_surcharge',
	},
	{
		id: 'action',
		label: 'staffing_table.action',
	},
	{
		id: 'error',
	},
]
