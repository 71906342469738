import React, { useCallback } from 'react'
import { ModalTemplate } from '@/ui'
import styles from './View.module.scss'
import { GroupWorkPlace } from './GroupWorkPlace'

export const View = ({ onClose, onToggleAction, groupRowSelectMenu }) => {
	const onEdit = useCallback(() => onToggleAction('edit', true), [onToggleAction])
	const onRemove = useCallback(() => onToggleAction('remove', true), [onToggleAction])
	return (
		<ModalTemplate onClose={onClose}>
			<div className={`scroll ${styles.wrapper} ${styles.wrapper_view}`}>
				<GroupWorkPlace
					onClose={onClose}
					onEdit={onEdit}
					onRemove={onRemove}
					groupRowSelectMenu={groupRowSelectMenu}
				></GroupWorkPlace>
			</div>
		</ModalTemplate>
	)
}
