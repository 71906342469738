import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Title } from '@/ui'
import styles from './GreateStaffing.module.scss'
import { TreeViewProject } from './TableProjectTreeView/TableProjectTreeView'
import { getFromLocalStorage, useDigitalSign, useToggle } from '@/shared'
import { StaffingStructure } from '@features/staffing/CreateStaffng/structureForm/StaffingStructure'
import { initialStaffing, initialStaffingGroup } from '@/entity/staffingProject/reducer'
import { View } from '@features/staffing/CreateStaffng/structureForm/view'
import { RemoveStruct } from './structureForm/Remove/RemoveStruct'
import { WorkPlaceModalForm } from '@features/staffing/CreateStaffng/Position/WorkPlaceModalForm'
import { View as ViewPosition } from './Position/view/View'
import { RemovePosition } from '@features/staffing/CreateStaffng/Position/Remove'
import { staffActions, staffSelectors, staffThunks } from '@/entity/staffingProject'
import { employerProfileSelectors } from '@features/employerProfile/model'
import { Form } from '@features/staffing/CreateStaffng/form'
import { contractActionsSelectors } from '@features/contracts/model'
import { StatusProject } from '@features/staffing/CreateStaffng/statusProject/statusProject'
import { ErrorInfoModal } from '@features/staffing/CreateStaffng/statusProject/ErrorInfoModal/ErrorInfoModal'
import { sumStaffUnitsCount, getGroupWorkPlaces } from '@/utils'
import { getCurrentLanguage } from '@/i18next'
import { staffingSelectors } from '@/entity'
import { GroupWorkPlaceModalForm } from '@features/staffing/CreateStaffng/groupWorkPlace/GroupWorkPlaceModalForm'
import { TreeViewProjectGroup } from '@features/staffing/CreateStaffng/TableProjectGroupTreeView/TableProjectGroupTreeView'
import { View as ViewGroupWorkPlace } from './groupWorkPlace/view/View'
import { RemoveGroup } from '@features/staffing/CreateStaffng/groupWorkPlace/Remove'

const CreateStaffing = () => {
	const { t } = useTranslation()
	const staffProject = useSelector(staffSelectors.staffProject)
	const activeCompany = useSelector(employerProfileSelectors.activeCompany)
	const { lmc } = activeCompany || {}
	/**
	 * Код статуса, полученный из селекторов проекта ШР. Он представляет текущее состояние проекта.
	 * Код статуса может иметь следующие значения:
	 *  - 0: "Обработка данных", "Деректерді өңдеу"
	 *  - 1: "Утверждён", "Бекітілген"
	 *  - 2: "Редактирование", "Өңдеу"
	 *  - 3: "Ошибка", "Қате"
	 *
	 * @type {string}
	 */
	const statusCode = useSelector(staffSelectors.statusCode)
	const { staffType, staffStatus } = useSelector(contractActionsSelectors.options)
	const staffXml = useSelector(staffSelectors.staffXml)
	const staffingTable = useSelector(staffingSelectors.staffing)
	const dispatch = useDispatch()
	const lang = getCurrentLanguage()
	const [isFirstCreate, toggleFirstCreate] = useToggle(false)
	const [isViewExtended, toggleViewExtended] = useToggle(false)
	const [isError, toggleIsError] = useToggle(false)
	const [rowSelectMenu, setRowSelectMenu] = useState(null)
	const [groupRowSelectMenu, setGroupRowSelectMenu] = useState(null)
	const [positionRowSelectMenu, setPositionRowSelectMenu] = useState(null)
	const [activeRowId, setActiveRowId] = useState(null)
	const { successPayload, onSign, failedPayload, onClearPayload } = useDigitalSign()
	const [isNewStaffing, setNewStaffing] = useState(true)
	let totalCount = 0

	const { orgsList, staffingGrants } = useSelector((state) => ({
		orgsList: state.administration.staffingBranchesList,
		staffingGrants: state.administration.staffingGrants,
	}))
	const activeStaffBranch = getFromLocalStorage('activeStaffBranch')
	const rightRegistration = staffingGrants?.data?.stRegistration

	useEffect(() => {
		let interval

		if (staffProject) {
			interval = setInterval(() => {
				if (statusCode === '0') {
					dispatch(staffThunks.getStatusProject())
				}
			}, 180000)
		}

		return () => {
			if (interval) {
				clearInterval(interval)
			}
		}
	}, [staffProject, dispatch])

	useEffect(() => staffXml && onSign(staffXml), [staffXml])

	useEffect(() => {
		failedPayload && onClear()
	}, [failedPayload])

	useEffect(() => {
		if (successPayload) {
			dispatch(staffThunks.sendSignedStaff(successPayload))
			onClearPayload()
		}
	}, [successPayload])

	if (staffProject) {
		totalCount = sumStaffUnitsCount(staffProject.childs)
	}

	const [actionsGroup, setActionsGroup] = useState({
		create: false,
		view: false,
		edit: false,
		remove: false,
	})

	const [actionsPositions, setActionsPositions] = useState({
		create: false,
		view: false,
		edit: false,
		remove: false,
	})

	const [actions, setActions] = useState({
		create: false,
		view: false,
		edit: false,
		remove: false,
	})

	const staffing = {
		...initialStaffing,
		...(lmc ? { ddistrictCode: '' } : {}),
		...(lmc ? { dregionCode: '' } : {}),
	}

	const groupWorkPlace = {
		...initialStaffingGroup,
	}

	const onToggleAction = (action, value) => {
		setActions((prevState) => {
			return { ...prevState, [action]: value }
		})
	}

	const onToggleActionGroup = (action, value) => {
		setActionsGroup((prevState) => {
			return { ...prevState, [action]: value }
		})
	}

	const onToggleActionPositions = (action, value) => {
		setActionsPositions((prevState) => {
			return { ...prevState, [action]: value }
		})
	}

	const Close = () => {
		toggleFirstCreate(false)
		onToggleAction('edit', false)
	}

	const onClose = () => {
		let actionObj = {
			create: false,
			view: false,
			edit: false,
			remove: false,
		}

		if (actions.view && (actions.edit || actions.create || actions.remove)) {
			actionObj.view = true
		}
		setActions((prevActions) => ({ ...prevActions, ...actionObj }))
	}

	const onCloseGroup = () => {
		let actionObj = {
			create: false,
			view: false,
			edit: false,
			remove: false,
		}

		if (actionsGroup.view && (actionsGroup.edit || actionsGroup.create || actionsGroup.remove)) {
			actionObj.view = true
		}
		setActionsGroup((prevActionsGroup) => ({ ...prevActionsGroup, ...actionObj }))
	}

	const onClosePosition = () => {
		let actionObj = {
			create: false,
			view: false,
			edit: false,
			remove: false,
		}

		if (actionsPositions.view && (actionsPositions.edit || actionsPositions.create || actionsPositions.remove)) {
			actionObj.view = true
		}
		setActionsPositions((prevActionsPositions) => ({ ...prevActionsPositions, ...actionObj }))
	}

	const removeProcess = () => {
		if (rowSelectMenu.level === 0) {
			dispatch(staffThunks.delProject())
			toggleViewExtended(false)
			toggleIsError(false)
			setRowSelectMenu(null)
			setGroupRowSelectMenu(null)
			setPositionRowSelectMenu(null)
			setActiveRowId(null)
		} else {
			let deleteStructure = {
				delExternalIds: [rowSelectMenu.externalId],
				orgExternalId: staffProject.externalId,
			}
			dispatch(staffThunks.delStructure(deleteStructure))
		}
		onToggleAction('remove', false)
		onToggleAction('view', false)
	}

	const removeGroupProcess = () => {
		dispatch(staffThunks.delGroupWorkPlace(groupRowSelectMenu.externalId))
		onToggleActionGroup('remove', false)
		onToggleActionGroup('view', false)
	}

	const removeWorkPlaceProcess = () => {
		let deleteWorkPlace = {
			delExternalIds: [positionRowSelectMenu.externalId],
			orgExternalId: staffProject.externalId,
			positionGroupExternalId: groupRowSelectMenu.externalId,
			structureExternalId: activeRowId,
		}
		dispatch(staffThunks.delWorkPlace(deleteWorkPlace))
		onToggleActionPositions('remove', false)
		onToggleActionPositions('view', false)
	}

	const signAndSend = () => {
		dispatch(staffThunks.getXML())
	}

	const onClear = () => {
		onClearPayload()
		dispatch(staffActions.setStaffXml(null))
	}

	const activeGroupWorkPlace = useMemo(
		() => (staffProject && staffProject?.externalId.length > 0 ? getGroupWorkPlaces(staffProject, activeRowId) : []),
		[staffProject, activeRowId]
	)

	const title = useMemo(() => {
		let res = orgsList.data?.find((item) => item.bin == activeStaffBranch)

		let byLang = {
			rus: res?.orgNameRu,
			kz: res?.orgNameKz,
		}

		return byLang?.[lang]
	}, [orgsList.data, activeStaffBranch, lang])

	return (
		<div>
			{isError ? (
				<ErrorInfoModal errors={staffProject.errors} onClose={() => toggleIsError(false)}></ErrorInfoModal>
			) : null}
			{rightRegistration && (
				<div className={styles.buttonPanel}>
					{!!staffProject ? null : (
						<Button
							onClick={() => {
								toggleFirstCreate(true)
								setNewStaffing(true)
							}}
						>
							{t('staffing_create')}
						</Button>
					)}
					{!!staffProject && !!staffingTable ? null : (
						<Button
							onClick={() => {
								toggleFirstCreate(true)
								setNewStaffing(false)
							}}
						>
							{t('staffing_create_from_table')}
						</Button>
					)}
					<Button onClick={signAndSend}>{t('sign_and_send')}</Button>
					{staffProject?.statusCode?.length > 0 && staffStatus ? (
						<StatusProject
							status={staffProject.statusCode}
							staffStatus={staffStatus}
							toggleIsError={toggleIsError}
						></StatusProject>
					) : null}
				</div>
			)}
			<div className={styles.countStaff}>
				<Title>{title || ''}</Title>
				<p>
					{t('count_staff_units_3')}
					<span>{totalCount}</span>
				</p>
			</div>
			<div className={styles.innerDiv}>
				<div className={styles.TreeViewProjectContainer}>
					<TreeViewProject
						toggleViewExtended={toggleViewExtended}
						setActions={setActions}
						staffProject={staffProject}
						staffType={staffType}
						setRowSelectMenu={setRowSelectMenu}
						activeRowId={activeRowId}
						setActiveRowId={setActiveRowId}
					/>
				</div>
				<div className={styles.divider} />
				<div className={styles.TablePositionContainer}>
					<div className={styles.ml15}>
						{isViewExtended ? (
							<>
								{rightRegistration && (
									<Button
										onClick={() => {
											onToggleActionGroup('create', true)
										}}
									>
										{t('staff.group_crate')}
									</Button>
								)}
								<TreeViewProjectGroup
									groupWorkPlace={activeGroupWorkPlace}
									setActions={setActionsGroup}
									setActionsPositions={setActionsPositions}
									setGroupRowSelectMenu={setGroupRowSelectMenu}
									setPositionRowSelectMenu={setPositionRowSelectMenu}
								></TreeViewProjectGroup>
							</>
						) : null}
					</div>
				</div>
			</div>
			{isFirstCreate || (rowSelectMenu?.level === 0 && actions.edit) ? (
				<Form
					staffProject={staffProject}
					actions={actions}
					onClose={Close}
					setRowSelectMenu={setRowSelectMenu}
					isNewStaffing={isNewStaffing}
				></Form>
			) : null}
			{actions.create || (rowSelectMenu?.level !== 0 && actions.edit) ? (
				<StaffingStructure
					onClose={onClose}
					initialValues={staffing}
					actions={actions}
					staffType={staffType}
					rowSelectMenu={rowSelectMenu}
					setRowSelectMenu={setRowSelectMenu}
					staffProject={staffProject}
				></StaffingStructure>
			) : null}
			{actions.view ? (
				<View
					onClose={onClose}
					onToggleAction={onToggleAction}
					rowSelectMenu={rowSelectMenu}
					staffProject={staffProject}
				></View>
			) : null}
			{actions.remove ? (
				<RemoveStruct onSubmit={removeProcess} onClose={onClose} rowSelectMenu={rowSelectMenu}></RemoveStruct>
			) : null}
			{actionsGroup.create || actionsGroup.edit ? (
				<GroupWorkPlaceModalForm
					initialValues={groupWorkPlace}
					onClose={onCloseGroup}
					actions={actionsGroup}
					activeRowId={activeRowId}
					staffProject={staffProject}
					groupRowSelectMenu={groupRowSelectMenu}
					lmc={lmc}
				></GroupWorkPlaceModalForm>
			) : null}
			{actionsPositions.edit ? (
				<WorkPlaceModalForm
					onClose={onClosePosition}
					activeRowId={activeRowId}
					staffProject={staffProject}
					groupRowSelectMenu={groupRowSelectMenu}
					positionRowSelectMenu={positionRowSelectMenu}
					setPositionRowSelectMenu={setPositionRowSelectMenu}
				></WorkPlaceModalForm>
			) : null}
			{actionsGroup.view ? (
				<ViewGroupWorkPlace
					onToggleAction={onToggleActionGroup}
					onClose={onCloseGroup}
					groupRowSelectMenu={groupRowSelectMenu}
				></ViewGroupWorkPlace>
			) : null}
			{actionsPositions.view && positionRowSelectMenu ? (
				<ViewPosition
					onToggleAction={onToggleActionPositions}
					onClose={onClosePosition}
					staffProject={staffProject}
					positionRowSelectMenu={positionRowSelectMenu}
				></ViewPosition>
			) : null}
			{actionsGroup.remove ? <RemoveGroup onSubmit={removeGroupProcess} onClose={onCloseGroup}></RemoveGroup> : null}
			{actionsPositions.remove ? (
				<RemovePosition onSubmit={removeWorkPlaceProcess} onClose={onClosePosition}></RemovePosition>
			) : null}
		</div>
	)
}

export default CreateStaffing
