import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Field as FormikField, Form as FormikForm, useFormikContext } from 'formik'
import styles from './StaffingFirstStructure.module.scss'
import { Button, Field, Title } from '@/ui'
import { Add, Trash } from '@app/icons'
import { useSelector } from 'react-redux'
import { employerProfileSelectors } from '@features/employerProfile/model'
import { contractActionsSelectors } from '@features/contracts/model'
import { filterKato } from '@/entity/handbook/thunks'

export const Form = ({ onClose, staffType, actions }) => {
	const activeCompany = useSelector(employerProfileSelectors.activeCompany)
	const { t } = useTranslation()
	const { values, setFieldValue } = useFormikContext()
	const { lmc } = activeCompany
	const { districts } = useSelector(contractActionsSelectors.options)
	const [unitRegions, setUnitRegions] = useState(values.units.map(() => []))

	const loadRegions = useCallback(async (code, unitIndex) => {
		if (code) {
			const response = await filterKato(code)
			setUnitRegions((prev) => {
				const updatedRegions = [...prev]
				updatedRegions[unitIndex] = response
				return updatedRegions
			})
		} else {
			setUnitRegions((prev) => {
				const updatedRegions = [...prev]
				updatedRegions[unitIndex] = []
				return updatedRegions
			})
		}
	}, [])

	useEffect(() => {
		values.units.forEach((unit, index) => {
			if (unit.ddistrictCode) {
				loadRegions(unit.ddistrictCode, index)
			}
		})
	}, [values.units, loadRegions])

	const addStructuralUnit = () => {
		setFieldValue('units', [
			...values.units,
			{
				subsidiary: false,
				binBranch: '',
				deptTypeCode: '',
				dept: {
					nameKz: '',
					nameRu: '',
					code: '',
				},
				ddistrictCode: '',
				dregionCode: '',
			},
		])
		setUnitRegions([...unitRegions, []]) // Add empty array for new unit
	}

	const removeUnits = (index) => {
		const updatedUnits = [...values.units]
		updatedUnits.splice(index, 1)
		setFieldValue('units', updatedUnits)
		setUnitRegions((prev) => {
			const updatedRegions = [...prev]
			updatedRegions.splice(index, 1)
			return updatedRegions
		})
	}

	// Вынесенная фильтрация
	const filterStaffType = (staffType, lmc) => {
		return staffType.filter((item) => {
			// Глобально всегда фильтруем только 'item.code === "1"'
			if (item.code === '1') return false
			// Если lmc не задан, убираем элементы с code '23', иначе оставляем как есть
			return lmc ? true : item.code !== '23'
		})
	}

	return (
		<FormikForm className={styles.form}>
			<section>
				<div className={styles.item}>
					<div className={`${styles.fields} ${styles.fields_verticalCenter}`}>
						<Title>{t('staff_modal.staff_information')}</Title>
					</div>
					{values.units.map((unit, unitIndex) => (
						<React.Fragment key={unitIndex}>
							<div className={`${styles.fields} ${styles.threeFields}`}>
								{unit.subsidiary ? (
									<FormikField name={`units.${unitIndex}.binBranch`}>
										{({ field, meta: { touched, error } }) => (
											<Field
												type="number"
												hideNumberArrows
												label={t('staff_modal.bin')}
												placeholder={t('staff_modal.bin_placeholder')}
												isRequired
												error={touched && error}
												{...field}
												maxLength={12}
											/>
										)}
									</FormikField>
								) : (
									<FormikField name={`units.${unitIndex}.deptTypeCode`}>
										{({ field, meta: { touched, error } }) => (
											<Field
												{...field}
												type="text"
												fieldType="select"
												label={t('staff_modal.structure_type')}
												placeholder={t('staff_modal.structure_type_placeholder')}
												isRequired
												error={touched && error}
												options={filterStaffType(staffType, lmc)}
											/>
										)}
									</FormikField>
								)}
							</div>
							<FormikField name={`units.${unitIndex}.dept.nameKz`}>
								{({ field, meta: { touched, error } }) => (
									<Field
										type="text"
										isRequired={(unit.dept.nameRu?.length || unit.dept.nameKz?.length) <= 1}
										label={t('staff_modal.structure_name_kz')}
										placeholder={t('staff_modal.structure_name_placeholder_kz')}
										error={touched && error}
										{...field}
									/>
								)}
							</FormikField>
							<FormikField name={`units.${unitIndex}.dept.nameRu`}>
								{({ field, meta: { touched, error } }) => (
									<Field
										type="text"
										isRequired={(unit.dept.nameRu?.length || unit.dept.nameKz?.length) <= 1}
										label={t('staff_modal.structure_name')}
										placeholder={t('staff_modal.structure_name_placeholder')}
										error={touched && error}
										{...field}
									/>
								)}
							</FormikField>
							{/*для ЦТМ с типом Карьерный центр*/}
							{unit.deptTypeCode === '23' && (
								<>
									<FormikField name={`units.${unitIndex}.ddistrictCode`}>
										{({ field, meta: { touched, error } }) => {
											const onChange = (code) => {
												loadRegions(code, unitIndex)
												setFieldValue(`units.${unitIndex}.ddistrictCode`, code)
												setFieldValue(`units.${unitIndex}.dregionCode`, null)
											}
											return (
												<Field
													type="text"
													fieldType="select"
													label={t('contracts_tab.region_work')}
													placeholder={t('choose_from_handbook')}
													options={districts}
													isRequired
													error={touched && error}
													{...field}
													onChange={onChange}
												/>
											)
										}}
									</FormikField>
									<FormikField name={`units.${unitIndex}.dregionCode`}>
										{({ field, meta: { touched, error } }) => {
											const onChange = (code) => {
												setFieldValue(`units.${unitIndex}.dregionCode`, code)
											}
											return (
												<Field
													type="text"
													fieldType="select"
													label={t('contracts_tab.distrcit_work')}
													placeholder={t('choose_from_handbook')}
													options={unitRegions[unitIndex]}
													isRequired
													error={touched && error}
													{...field}
													onChange={onChange}
												/>
											)
										}}
									</FormikField>
								</>
							)}
							{actions.create
								? unitIndex > 0 && (
										<Button
											transparent
											onClick={() => removeUnits(unitIndex)}
											buttonClassName={styles.buttonRed}
											disabled={false}
										>
											<Trash className={styles.iconRed} />
											{t('remove')}
										</Button>
								  )
								: null}
						</React.Fragment>
					))}
					{actions.create ? (
						<Button transparent onClick={addStructuralUnit} buttonClassName={styles.button}>
							<Add className={styles.iconGreen} />
							{t('staff_modal.structure_add')}
						</Button>
					) : null}
				</div>
			</section>
			<div className={styles.actions}>
				<Button type="bare" onClick={onClose}>
					{t('cancel')}
				</Button>
				<Button textColor="green" borderColor="green" transparent border type="submit">
					{t('save')}
				</Button>
			</div>
		</FormikForm>
	)
}
