import React, { useEffect, useMemo, useState } from 'react'
import { TreeView } from '@features/staffing/tableTreeView/TableTreeView'
import { SendPeople } from '@app/images'
import { Button, Title } from '@/ui'
import { useTranslation } from 'react-i18next'
import { getFromLocalStorage, useDigitalSign, useToggle } from '@/shared'
import styles from './Staffing.module.scss'
import { StaffingPositionForm } from './StaffingPositionForm'
import { initialStaffingPosition } from '@/entity/staffingProject/reducer'
import { useDispatch, useSelector } from 'react-redux'
import { staffingActions, staffingSelectors, staffingThunks } from '@/entity/staffing'
import { View } from './StaffingPositionForm/view/View'
import { contractActionsSelectors } from '@features/contracts/model'
import {
	calculateGroupStaffUnits,
	calculateStaffUnits,
	getDeptAndDeptTypeCodeByExternalId,
	getGroupWorkPlaces,
} from '@/utils'
import ModalGetNewVersion from './ModalGetNewVersion/ModalGetNewVersion'
import { InfoWarning } from '@/components/infoWarning'
import { getCurrentLanguage } from '@/i18next'
import { TreeViewProjectGroup } from '@features/staffing/CreateStaffng/TableProjectGroupTreeView/TableProjectGroupTreeView'
import { Arrow } from '@app/icons'
import { WrappedCheckbox } from '@features/contracts/registrationEmployerContract/form/fields'
import StaffUnitsCount from '@features/staffing/components/StaffUnitsCount'

const Staffing = () => {
	const { t } = useTranslation()
	const staffing = useSelector(staffingSelectors.staffing)
	const esutdInfo = useSelector(staffingSelectors.esutdInfo)
	const [totalStaffUnits, setTotalStaffUnits] = useState(0)
	const [totalFreeStaffUnits, setTotalFreeStaffUnits] = useState(0)
	const { staffType } = useSelector(contractActionsSelectors.options)
	const staffXML = useSelector(staffingSelectors.staffXML)
	const employeeListXML = useSelector(staffingSelectors.employeeListXML)
	const dispatch = useDispatch()
	const [isViewExtended, toggleViewExtended] = useToggle(false)
	const [activeStructId, setActiveStructId] = useState(null)
	const [workPlaceRowSelect, setWorkPlaceRowSelect] = useState(null)
	const [openModalSign, setOpenModalSign] = useToggle(false)
	const [deactivateStaff, setDeactivateStaff] = useState(false)
	const [isFreelance, toggleFreelance] = useToggle(false)
	const { successPayload, onSign, onSignMultiple, failedPayload, onClearPayload } = useDigitalSign()
	const lang = getCurrentLanguage()
	const optionLang = useMemo(() => (lang === 'rus' ? 'nameRu' : 'nameKz'), [lang])
	const [actions, setActions] = useState({
		create: false,
		view: false,
		edit: false,
	})
	const activeStaffBranch = getFromLocalStorage('activeStaffBranch')

	const { orgsList, staffingGrants } = useSelector((state) => ({
		orgsList: state.administration.staffingBranchesList,
		staffingGrants: state.administration.staffingGrants,
	}))

	const title = useMemo(() => {
		let res = orgsList.data?.find((item) => item.bin == activeStaffBranch)

		let byLang = {
			rus: res?.orgNameRu,
			kz: res?.orgNameKz,
		}

		return byLang?.[lang]
	}, [orgsList.data, activeStaffBranch, lang])

	const staffingPosition = {
		...initialStaffingPosition,
	}

	const activeGroupWorkPlace = useMemo(() => {
		let filteredGroupWorkPlace
		if (staffing && staffing?.externalId.length > 0) {
			filteredGroupWorkPlace = getGroupWorkPlaces(staffing, activeStructId)

			// Если isFreelance === true, фильтруем workPlaces
			if (isFreelance) {
				filteredGroupWorkPlace = filteredGroupWorkPlace?.filter((place) => place.freelance === true)
			}
		}
		return filteredGroupWorkPlace
	}, [staffing, activeStructId, isFreelance])

	const structure = useMemo(
		() =>
			staffing && staffing?.externalId.length > 0 && activeStructId !== null
				? getDeptAndDeptTypeCodeByExternalId(staffing, activeStructId)
				: '',
		[staffing, activeStructId]
	)

	const showFullNameStruct = (code) => {
		const deptType = staffType.find((item) => item.code === code)
		return deptType ? deptType[lang] + ': ' + structure.dept[optionLang] : null
	}

	useEffect(() => {
		const updateStaffUnits = (calculateFn, data) => {
			const { totalStaffUnits, totalFreeStaffUnits } = calculateFn(data)
			setTotalStaffUnits(totalStaffUnits)
			setTotalFreeStaffUnits(totalFreeStaffUnits)
		}

		if (staffing?.actual) {
			if (isViewExtended) {
				updateStaffUnits(calculateGroupStaffUnits, activeGroupWorkPlace)
			} else {
				updateStaffUnits(calculateStaffUnits, staffing.childs[0])
			}
		}

		if (esutdInfo?.preparedStatus === 'PREPARED') {
			setOpenModalSign(true)
			setDeactivateStaff(true)
		}
	}, [staffing, structure, isViewExtended])

	useEffect(() => staffXML && onSign(staffXML), [staffXML])

	useEffect(() => employeeListXML && onSignMultiple(employeeListXML), [employeeListXML])

	useEffect(() => {
		failedPayload && onClear()
	}, [failedPayload])

	useEffect(() => {
		if (successPayload) {
			if (!employeeListXML) {
				dispatch(staffingThunks.sendActualSignStaffing(successPayload))
			} else {
				dispatch(staffingThunks.sendEmployeeList(successPayload))
			}
			onClearPayload()
		}
	}, [successPayload])

	const onClose = () => {
		let actionObj = {
			create: false,
			view: false,
		}

		if (actions.view && actions.create) {
			actionObj.create = false
			actionObj.view = true
		}
		if (!actions.view) {
			dispatch(staffingActions.resetWPlaceEmployee())
		}

		setActions((prevActions) => ({ ...prevActions, ...actionObj }))
		setOpenModalSign(false)
	}
	const onToggleAction = (action, value) => {
		setActions((prevState) => {
			return { ...prevState, [action]: value }
		})
	}

	const signAndSendActualStaff = () => {
		dispatch(staffingThunks.getActualXml(staffing.externalId))
		onClose()
	}

	const signAndSendEmployee = () => {
		dispatch(staffingThunks.getEmployeeListXml())
	}

	const onClear = () => {
		onClearPayload()
		dispatch(staffingActions.setStaffXml(null))
		dispatch(staffingActions.setEmployeeXml(null))
	}

	const handleCheckboxChange = ({ value }) => {
		toggleFreelance(value)
	}

	return (
		<>
			{openModalSign ? (
				<ModalGetNewVersion onSubmit={signAndSendActualStaff} onClose={onClose}></ModalGetNewVersion>
			) : null}
			{deactivateStaff ? (
				<div className={styles.mb}>
					<InfoWarning />
				</div>
			) : null}
			<div className={styles.buttonSearchPanel}>
				{!!staffing && !deactivateStaff && staffingGrants?.data?.stRegistration ? (
					<Button
						icon={SendPeople}
						iconSize={'big'}
						onClick={() => {
							signAndSendEmployee()
						}}
					>
						{t('send_appointment')}
					</Button>
				) : null}
			</div>
			{isViewExtended ? (
				<>
					<div className={styles.linePreTable}>
						<div onClick={() => toggleViewExtended(false)} className={styles.back}>
							<Arrow styles={{ fill: '#17B67C', width: '5px' }} />
							{t('back')}
						</div>
						<WrappedCheckbox
							onChange={handleCheckboxChange}
							value={isFreelance}
							label={t('staff_modal.freelancer_position')}
						/>
					</div>
					<div className={styles.countStaff}>
						<Title>{showFullNameStruct(structure.deptTypeCode)}</Title>
						<StaffUnitsCount t={t} totalStaffUnits={totalStaffUnits} totalFreeStaffUnits={totalFreeStaffUnits} />
					</div>
					<TreeViewProjectGroup
						groupWorkPlace={activeGroupWorkPlace}
						//12.12.2024 раскомментировать при реализации редактирования утверждённой штатки
						// setActions={setActions}
						setActionsPositions={setActions}
						setGroupRowSelectMenu={setWorkPlaceRowSelect}
						setPositionRowSelectMenu={setWorkPlaceRowSelect}
						isProject={false}
					></TreeViewProjectGroup>
					{actions.create ? (
						<StaffingPositionForm
							initialValues={staffingPosition}
							onClose={onClose}
							actions={actions}
							setActions={setActions}
							workPlaceRowSelect={workPlaceRowSelect}
						></StaffingPositionForm>
					) : null}
					{actions.view && !!activeGroupWorkPlace ? (
						<View
							onClose={onClose}
							onToggleAction={onToggleAction}
							workPlaceRowSelect={workPlaceRowSelect}
							deactivateStaff={deactivateStaff}
							groupWorkPlace={activeGroupWorkPlace}
						></View>
					) : null}
				</>
			) : (
				<div className={styles.mt}>
					<div className={styles.countStaff}>
						<Title>{title || ''}</Title>
						<StaffUnitsCount t={t} totalStaffUnits={totalStaffUnits} totalFreeStaffUnits={totalFreeStaffUnits} />
					</div>
					{staffing?.currVersion ? (
						<div className={styles.information}>
							<div className={styles.tableText}>
								{t('staff.version') +
									staffing?.currVersion +
									', ' +
									t('staff_modal.order_number') +
									': ' +
									staffing?.orderNumber +
									', ' +
									t('staff_modal.order_date') +
									': ' +
									staffing?.orderDate}
							</div>
						</div>
					) : null}
					<TreeView
						staffing={staffing}
						staffType={staffType}
						toggleViewExtended={toggleViewExtended}
						activeStructId={activeStructId}
						setActiveStructId={setActiveStructId}
					/>
				</div>
			)}
		</>
	)
}

export default Staffing
