import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { headCells } from './tableViewWorkplace.headings'
import { StatusSent } from './StatusSent'
import styles from './tableViewWorkplace.module.scss'
import DropDownMenu from '@/components/DropdownMenu/DropdownMenu'
import { useSelector } from 'react-redux'
import { staffingSelectors } from '@/entity'
import { LoadingScreen } from '@/components/Loader'
import { Title } from '@/ui'
import { formatNumber } from '@/utils'
import { Edit as EditIcon, Remove as RemoveIcon, View as ViewIcon } from '@app/images'

export const TableViewWorkplace = ({ setEditStaffingPosition, setActions, deactivateStaff }) => {
	const { t } = useTranslation()
	const [actionMenuItems, setActionMenuItems] = useState([])
	const wplaceEmployeeList = useSelector(staffingSelectors.wplaceEmployeeList)
	const isLoading = useSelector(staffingSelectors.isLoading)
	const { staffingGrants } = useSelector((state) => ({
		staffingGrants: state.administration.staffingGrants,
	}))
	const getSubstitutionNameForId = (substitutionExternalId) => {
		const substitution = wplaceEmployeeList.find((item) => item.externalId === substitutionExternalId)
		return {
			substitutionName: substitution ? substitution.fullName : '',
		}
	}

	const onToggleAction = (action, value, row) => {
		setActions((prevState) => {
			return { ...prevState, [action]: value }
		})
		setEditStaffingPosition(row)
	}

	const onActionMenuChoose = (mode, row) => {
		onToggleAction(mode, true, row)
	}
	const tableHeader = useMemo(
		() => (
			<>
				{(deactivateStaff ? headCells.slice(0, -1) : headCells).map((head, index) => (
					<th key={index + 'thead-th'}>{t(head.label)}</th>
				))}
			</>
		),
		[t]
	)

	const fastView = (row) => {
		onToggleAction('view', true, row)
	}

	const onWorkplaceMenuClick = (row, e, setActionMenuItems, t) => {
		if (e) {
			e.preventDefault()
			e.stopPropagation()
		}

		let actions = [
			{ icon: ViewIcon, title: t('actions.view'), action: 'view' },
			...(staffingGrants.data?.stRegistration
				? [
						{ icon: EditIcon, title: t('actions.edit'), action: 'edit' },
						{ icon: RemoveIcon, title: t('actions.remove'), action: 'remove' },
				  ]
				: []),
		]

		setActionMenuItems(actions)
	}

	const getTableRow = useCallback((row, index) => (
		<tr key={index} onClick={() => fastView(row)}>
			<td>{row.fullName}</td>
			<td>{getSubstitutionNameForId(row.substitutionExternalId).substitutionName}</td>
			<td>{row?.isCombining ? t('yes') : t('no') || ''}</td>
			<td>{row.tariffRate}</td>
			<td>{formatNumber(row.factSalary || '')}</td>
			<td>{row.beginDate}</td>
			<td>{row.endDate}</td>
			<td>
				<StatusSent status={row.statusCode} errorCode={row.errorCode} />
			</td>
			{!deactivateStaff ? (
				<td onClick={(e) => onWorkplaceMenuClick(row, e, setActionMenuItems, t)}>
					<DropDownMenu
						title={t('select_pls')}
						items={actionMenuItems}
						onItemClick={(mode) => onActionMenuChoose(mode, row)}
					/>
				</td>
			) : null}
		</tr>
	))
	const tableBody = useMemo(
		() => wplaceEmployeeList?.map((row, index) => getTableRow(row, index)),
		[wplaceEmployeeList, getTableRow]
	)
	return (
		<div>
			<table className={styles.table}>
				<thead>
					<tr>{tableHeader}</tr>
				</thead>
				<tbody>
					{!isLoading ? (
						tableBody
					) : (
						<tr>
							<td colSpan={10} className={styles.messageCol}>
								<LoadingScreen>
									<Title>
										{t('staff.stop')} <br />
										{t('labor_records.loading')}
									</Title>
								</LoadingScreen>
								{/*{!isLoading && <div className={styles.error}>{t('has_error')}</div>}*/}
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	)
}
