export function filterWorkPlaceByExternalId(obj, externalId) {
	if (Array.isArray(obj)) {
		for (let child of obj) {
			if (child) {
				// рекурсивный вызов
				let foundItem = filterWorkPlaceByExternalId(child, externalId)

				// Если найдено соответствующее workplace, оно сразу возвращается
				if (foundItem !== null) {
					return foundItem
				}
			}
		}
	} else if (typeof obj === 'object' && obj !== null) {
		if (Array.isArray(obj.workPlaces) && obj.workPlaces.length > 0) {
			let foundWorkPlace = obj.workPlaces.find((workPlace) => workPlace.externalId === externalId)

			// Как только найдено соответствующее workplace, оно возвращается
			if (foundWorkPlace) {
				return foundWorkPlace
			}
		}
		if (obj.childs) {
			// рекурсивный вызов
			let foundChild = filterWorkPlaceByExternalId(obj.childs, externalId)

			// Если найдено соответствующее workplace, оно сразу возвращается
			if (foundChild !== null) {
				return foundChild
			}
		}
	}

	return null
}
