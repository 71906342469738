export const sumStaffUnitsCount = (childs) => {
	if (!Array.isArray(childs)) {
		return 0
	}

	return childs.reduce((sum, child) => {
		if (!child) {
			return sum
		}

		let count = 0

		// Add up staffUnitsCount of the current child's positionGroups
		if (Array.isArray(child.positionGroups)) {
			count += child.positionGroups.reduce((groupSum, positionGroup) => {
				return groupSum + (parseFloat(positionGroup.staffUnitsCount) || 0)
			}, 0)
		}

		// Add up staffUnitsCount of the current child's workPlaces
		if (Array.isArray(child.workPlaces)) {
			count += child.workPlaces.reduce((workPlaceSum, workPlace) => {
				return workPlaceSum + (parseFloat(workPlace.staffUnitsCount) || 0)
			}, 0)
		}

		// Recursively process childs of the current child
		count += sumStaffUnitsCount(child.childs)

		return sum + count
	}, 0)
}
