import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ModalTemplate, Title } from '@/ui'
import styles from './PositionForm.module.scss'
import { Formik } from 'formik'
import { WorkPlaceForm } from './WorkPlaceForm'
import { useDispatch, useSelector } from 'react-redux'
import { staffSelectors, staffThunks } from '@/entity/staffingProject'
import { ValidationSchema } from './validationSchema'
import { contractActionsSelectors } from '@features/contracts/model'
import { LoadingBlocker } from '@/components/Loader'
import { filterWorkPlaceByExternalId } from './utils'

export const WorkPlaceModalForm = ({
	onClose,
	activeRowId,
	staffProject,
	groupRowSelectMenu,
	positionRowSelectMenu,
	setPositionRowSelectMenu,
}) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const isSuccess = useSelector(staffSelectors.isSuccess)
	const { positions, positionsByKnz, laborMobilityCenterCategory } = useSelector(contractActionsSelectors.options)

	useEffect(() => {
		if (isSuccess) {
			let workPlace = filterWorkPlaceByExternalId(staffProject.childs[0], editPosition.externalId)
			setPositionRowSelectMenu(workPlace)
			onClose()
		}
	}, [isSuccess])

	let establishedPostNames = { ...positionRowSelectMenu.establishedPost }
	let otherProps = { ...positionRowSelectMenu }

	// добавляем поле isDicPosition в объект editPosition
	let editPosition = {
		...(establishedPostNames || {}),
		...(otherProps || {}),
		isDicPosition: !establishedPostNames.code ? '2' : '3',
		position: establishedPostNames.code ? establishedPostNames.code : null,
	}

	const onSubmit = (data) => {
		let position = positions?.find((item) => item.code === data.position)
		const workPlaceData = {
			orgExternalId: staffProject.externalId,
			positionGroupExternalId: groupRowSelectMenu.externalId,
			row: {
				establishedPost: {
					nameKz: data.isDicPosition === '2' ? data.nameKz : position.kz,
					nameRu: data.isDicPosition === '2' ? data.nameRu : position.rus,
					code: data.isDicPosition === '2' ? null : position.code,
				},
				positionCode: data.positionCode,
				staffCategory: data.staffCategory,
				factSalary: data.factSalary,
				freelance: data.freelance,
				grossSalary: data.grossSalary,
				salarySupplement: data.salarySupplement,
				staffFreeCount: data.staffFreeCount,
				staffUnitsCount: data.staffUnitsCount,
				code: data.code,
				externalId: editPosition.externalId,
			},
			structureExternalId: activeRowId,
		}
		dispatch(staffThunks.editWorkPlace(workPlaceData))
	}

	return (
		<ModalTemplate onClose={onClose} priority={2}>
			<div className={`scroll ${styles.wrapper}`}>
				<div className={styles.mt10}>
					<Title color="black">{t('staff_modal.edit_position')}</Title>
					<Formik
						validationSchema={ValidationSchema}
						initialValues={editPosition}
						onSubmit={onSubmit}
						enableReinitialize
					>
						{positions?.length > 0 && positionsByKnz.length > 0 && laborMobilityCenterCategory.length > 0 ? (
							<WorkPlaceForm
								onClose={onClose}
								positions={positions}
								positionsByKnz={positionsByKnz}
								laborMobilityCenterCategory={laborMobilityCenterCategory}
							></WorkPlaceForm>
						) : (
							<LoadingBlocker localStyles={{ width: '65vw', height: '50vw' }} />
						)}
					</Formik>
				</div>
			</div>
		</ModalTemplate>
	)
}
