import { request } from '@/shared/api/request'

export const deleteWorkPlace = ({ data, params, payload: { wpId } }) => {
	return request({
		method: 'delete',
		url: `staff/wplace/${wpId}/delete`,
		data,
		params,
	})
}
