export const formFieldNames = {
	isDicPosition: 'isDicPosition',
	code: 'code',
	nameKz: 'nameKz',
	nameRu: 'nameRu',
	destablishedPost: 'destablishedPost',
	dposition: 'dposition',
	dstaffCategory: 'dstaffCategory',
	factSalary: 'factSalary',
	freelance: 'freelance',
	grossSalary: 'grossSalary',
	salarySupplement: 'salarySupplement',
	staffUnitsCount: 'staffUnitsCount',
}
