export const calculateGroupStaffUnits = (data) => {
	let totalStaffUnits = 0
	let totalFreeStaffUnits = 0

	for (let item of data) {
		if (item.workPlaces) {
			for (let workplace of item.workPlaces) {
				totalStaffUnits += workplace.staffUnitsCount || 0 // прибавляем staffUnitsCount, если значение есть
				totalFreeStaffUnits += workplace.staffFreeCount || 0 // прибавляем staffFreeCount, если значение есть
			}
		}

		// Также учитываем верхний уровень позиции
		totalStaffUnits += item.staffUnitsCount || 0
		totalFreeStaffUnits += item.staffFreeCount || 0
	}

	return { totalStaffUnits, totalFreeStaffUnits }
}
