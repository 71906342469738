import { request } from '@/shared/api/request'

export const removeWorkPlace = ({ data, params }) => {
	return request({
		url: 'staff/workPlace/del',
		method: 'POST',
		data,
		params,
	})
}
