import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Title } from '@/ui'
import styles from './View.module.scss'
import { Edit, Trash } from '@app/icons'
import { GroupWorkPlaceInformation } from './GroupWorkPlaceInformation'
import { getCurrentLanguage } from '@/i18next'
import { useSelector } from 'react-redux'

export const GroupWorkPlace = ({ onClose, onEdit, onRemove, groupRowSelectMenu }) => {
	const { t } = useTranslation()
	const lang = getCurrentLanguage()
	const optionLang = lang === 'rus' ? 'nameRu' : 'nameKz'

	const { staffingGrants } = useSelector((state) => ({
		staffingGrants: state.administration.staffingGrants,
	}))

	return (
		<div>
			<Title color="black">{groupRowSelectMenu.establishedPost[optionLang]}</Title>
			{staffingGrants?.data?.stRegistration && (
				<div className={styles.actions}>
					<Button
						onClick={onEdit}
						buttonClassName={styles.button}
						textColor="green"
						borderColor="green"
						transparent
						border
					>
						<Edit className={styles.iconGreen} />
						{t('edit_button')}
					</Button>
					<Button onClick={onRemove} buttonClassName={styles.button} type="bare">
						<Trash className={styles.iconGrey} />
						{t('remove')}
					</Button>
				</div>
			)}
			<div className={styles.informationSections}>
				<section>
					<GroupWorkPlaceInformation groupRowSelectMenu={groupRowSelectMenu}></GroupWorkPlaceInformation>
				</section>
			</div>
			<div className={styles.close}>
				<Button onClick={onClose}>{t('close')}</Button>
			</div>
		</div>
	)
}
