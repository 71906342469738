import { api, getFromLocalStorage, setToLocalStorage } from '@/shared'
import { message } from 'antd'
import { actions as administrationActions } from '@/entity/administration/actions'
import { staffingThunks, staffThunks } from '@/entity'
import { administrationThunks } from '@/entity/administration/index'
import { getCurrentLanguage } from '@/i18next'

export const getBranchesList = (payload) => {
	return async (dispatch) => {
		try {
			dispatch(administrationActions.getBranchesAction())

			const res = await api.administration.getBranches(payload)

			if (res) {
				dispatch(administrationActions.getBranchesActionDone(res))
			}
		} catch (error) {
			message.error(error.message)

			dispatch(administrationActions.getBranchesActionFail())
		}
	}
}

export const setGrant = (payload) => {
	const { type, data, onSuccess } = payload

	return async (dispatch) => {
		try {
			dispatch(administrationActions.setGrandAction())

			if (type === 'request') {
				await api.administration.requestGrant(data)
			} else {
				await api.administration.revokeGrant(data)
			}

			let body = {
				...data,
				type: type,
			}

			dispatch(administrationActions.setGrandActionDone(body))
		} catch (error) {
			message.error(error.message)

			dispatch(administrationActions.setGrandActionFail())
		}
	}
}

export const setSign = (payload) => {
	const { data, onSuccess } = payload

	return async (dispatch) => {
		try {
			dispatch(administrationActions.setSignAction())

			const res = await api.administration.getXml(data)

			if (res) {
				dispatch(administrationActions.setSignActionDone(res))

				onSuccess(res)
			}
		} catch (error) {
			message.error(error.message)

			dispatch(administrationActions.setSignActionFail())
		}
	}
}

export const sendSignedXml = (payload) => {
	const { data, changedData, onSuccess } = payload

	return async (dispatch) => {
		try {
			dispatch(administrationActions.sendSignedXml())

			await api.administration.sendGrants(data)

			dispatch(administrationActions.sendSignedXmlDone(changedData))

			onSuccess()
		} catch (error) {
			message.error(error.message)

			dispatch(administrationActions.sendSignedXmlFail())
		}
	}
}

export const setOneBranch = (payload) => {
	return async (dispatch, getState) => {
		try {
			dispatch(administrationActions.setStaffingAdminOrgAction())

			const res = await api.staff.setStaffingBranch(payload.bin)

			if (res) {
				await setToLocalStorage('activeStaffBranch', payload.bin)
				dispatch(administrationActions.setStaffingAdminOrgActionDone(payload))
				dispatch(administrationThunks.getStaffingGrants(payload.bin))

				dispatch(staffingThunks.getActualStaff())
				dispatch(staffThunks.getProject())
			}
		} catch (error) {
			message.error(error.message)

			dispatch(administrationActions.setStaffingAdminOrgActionFail())
		}
	}
}

export const getStaffingBranchesList = (payload) => {
	const { data, onSuccess } = payload
	return async (dispatch) => {
		try {
			const activeCompany = getFromLocalStorage('activeCompany')
			const lang = getCurrentLanguage()

			dispatch(administrationActions.getStaffingAdminOrgsAction())

			let body = {
				data: data,
			}

			const res = await api.staff.getStaffingBranches(body)

			if (res?.isError) {
				let currentLang = {
					rus: res?.messageRu,
					kz: res?.messageKz,
				}

				message.warn(currentLang[lang])
			}

			if (res?.orgList?.length) {
				dispatch(administrationActions.getStaffingAdminOrgsActionDone(res?.orgList))

				let result = res?.orgList?.find((item) => item.bin == activeCompany?.bin)
				onSuccess(result || { bin: activeCompany?.bin })
			}
		} catch (error) {
			message.error(error.message)

			dispatch(administrationActions.getStaffingAdminOrgsActionFail())
		}
	}
}

export const getStaffingGrants = (payload) => {
	return async (dispatch) => {
		try {
			const activeCompany = getFromLocalStorage('activeCompany')

			dispatch(administrationActions.getStaffingGrantsAction())

			if (activeCompany?.bin == payload) {
				let res = {
					bin: payload,
					stRegistration: true,
					stView: true,
				}

				dispatch(administrationActions.getStaffingGrantsActionDone(res))
			} else {
				let body = {
					params: {
						grantorBin: payload,
					},
				}
				const res = await api.staff.getStaffingGrants(body)

				if (res) {
					dispatch(administrationActions.getStaffingGrantsActionDone(res))
				}
			}
		} catch (error) {
			message.error(error.message)

			dispatch(administrationActions.getStaffingGrantsActionFail())
		}
	}
}
