import React, { useEffect } from 'react'
import { ModalTemplate, Title } from '@/ui'
import styles from './GroupWorkPlaceModalForm.module.scss'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import { ValidationSchema } from './validationSchema'
import { GroupWorkPlaceForm } from './GroupWorkPlaceForm'
import { useDispatch, useSelector } from 'react-redux'
import { contractActionsSelectors } from '@features/contracts/model'
import { LoadingBlocker } from '@/components/Loader'
import { staffSelectors, staffThunks } from '@/entity/staffingProject'
import { GroupWorkPlaceLmcForm } from '@features/staffing/CreateStaffng/groupWorkPlace/GroupWorkPlaceLmcForm'

function mapDictData(data, position, positionByKnz, laborMobilityCenterCategorys) {
	const isDicPosition2 = data.isDicPosition === '2'

	return {
		destablishedPost: {
			code: isDicPosition2 ? null : position.code,
			nameKz: isDicPosition2 ? data.nameKz : position.kz,
			nameRu: isDicPosition2 ? data.nameRu : position.rus,
		},
		dposition: {
			code: positionByKnz.code,
			nameKz: positionByKnz.kz,
			nameRu: positionByKnz.rus,
		},
		dstaffCategory: {
			code: laborMobilityCenterCategorys.code,
			nameKz: laborMobilityCenterCategorys.kz,
			nameRu: laborMobilityCenterCategorys.rus,
		},
	}
}

export const GroupWorkPlaceModalForm = ({
	initialValues,
	onClose,
	actions,
	staffProject,
	activeRowId,
	groupRowSelectMenu,
	lmc, //Признак ЦТМ (Центра трудовой мобильности)
}) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { edit, create } = actions
	const isSuccess = useSelector(staffSelectors.isSuccess)
	const { positions, positionsByKnz, laborMobilityCenterCategory } = useSelector(contractActionsSelectors.options)

	useEffect(() => {
		if (isSuccess) {
			onClose()
		}
	}, [isSuccess])

	const onSubmit = (data) => {
		let position = positions?.find((item) => item.code === data.destablishedPost)
		let positionByKnz = positionsByKnz?.find((item) => item.code === data.dposition)
		let laborMobilityCenterCategorys = laborMobilityCenterCategory?.find((item) => item.code === data.dstaffCategory)
		const dictMapData = mapDictData(data, position, positionByKnz, laborMobilityCenterCategorys)
		let newGroupWorkPlace = {}
		if (create) {
			newGroupWorkPlace = {
				...dictMapData,
				factSalary: data.factSalary,
				freelance: data.freelance,
				grossSalary: data.grossSalary,
				orgExternalId: staffProject.externalId,
				salarySupplement: data.salarySupplement,
				staffUnitsCount: data.staffUnitsCount,
				structureExternalId: activeRowId,
			}
			dispatch(staffThunks.createGroupWorkPlace(newGroupWorkPlace))
		} else {
			newGroupWorkPlace = {
				...dictMapData,
				posGroupExternalId: groupRowSelectMenu.externalId,
				freelance: groupRowSelectMenu.freelance,
			}
			dispatch(staffThunks.editGroupWorkPlace(newGroupWorkPlace, activeRowId))
		}
	}

	let establishedPostNames,
		otherProps,
		editGroupWorkPlace = null
	if (edit && groupRowSelectMenu) {
		establishedPostNames = { ...groupRowSelectMenu.establishedPost }
		otherProps = { ...groupRowSelectMenu }
		editGroupWorkPlace = {
			...(establishedPostNames || {}),
			...(otherProps || {}),
			isDicPosition: !groupRowSelectMenu.establishedPost.code ? '2' : '3',
			destablishedPost: establishedPostNames.code ? establishedPostNames.code : null,
			dposition: groupRowSelectMenu?.dposition?.code ? groupRowSelectMenu?.dposition?.code : null,
			dstaffCategory: groupRowSelectMenu?.dstaffCategory?.code ? groupRowSelectMenu?.dstaffCategory?.code : null,
		}
	}

	return (
		<ModalTemplate onClose={onClose} priority={edit || create ? 2 : 1}>
			<div className={`scroll ${styles.wrapper}`}>
				<Title color="black">{create ? t('staff.create_group') : t('staff.edit_group')}</Title>
				<Formik
					initialValues={create ? initialValues : editGroupWorkPlace}
					validationSchema={ValidationSchema(edit)}
					onSubmit={onSubmit}
				>
					{positions?.length > 0 && positionsByKnz?.length > 0 && laborMobilityCenterCategory?.length > 0 ? (
						<>
							{lmc ? (
								<GroupWorkPlaceLmcForm onClose={onClose} edit={edit} />
							) : (
								<GroupWorkPlaceForm onClose={onClose} edit={edit} />
							)}
						</>
					) : (
						<LoadingBlocker localStyles={{ width: '65vw', height: '50vw' }} />
					)}
				</Formik>
			</div>
		</ModalTemplate>
	)
}
