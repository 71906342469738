import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Field, ModalTemplate, Title } from '@/ui'
import { ModalSolutionOutPositionSchema } from './ModalSolutionOutPosition.schema'
import { Form, Formik, Field as FormikField } from 'formik'
import styles from './ModalSolutionOutPosition.module.scss'

export const ModalSolutionOutPosition = ({ onSubmit, handleCancel, editStaffingPosition }) => {
	const { t } = useTranslation()
	const handleDataProcess = (data) => {
		const toSubmit = {
			externalId: editStaffingPosition.externalId,
			cause: data.cause,
		}
		onSubmit(toSubmit)
	}
	return (
		<ModalTemplate onClose={handleCancel} hideBackButton={true}>
			<Formik
				initialValues={{
					cause: null,
				}}
				onSubmit={handleDataProcess}
				validationSchema={ModalSolutionOutPositionSchema}
				enableReinitialize
			>
				{({ isValid, values }) => {
					return (
						<Form>
							<div className={styles.content}>
								<Title color={'darkBlue'}>{t('staff_modal.title')}</Title>
							</div>
							<FormikField name="cause">
								{({ field, meta: { touched, error } }) => (
									<Field
										{...field}
										type="text"
										fieldType={'textarea'}
										placeholder={t('staff_modal.note_placeholder')}
										error={touched && error ? t(error) : ''}
										showCount
										maxLength={4000}
										rows={6}
										labelClassName={styles.field}
									/>
								)}
							</FormikField>
							<div className={styles.buttonStyle}>
								<Button disabled={!isValid || values.cause === ''} type="submit">
									{t('staff_modal.yes')}
								</Button>
								<Button type="bare" onClick={handleCancel}>
									{t('staff_modal.no')}
								</Button>
							</div>
						</Form>
					)
				}}
			</Formik>
		</ModalTemplate>
	)
}
