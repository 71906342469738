import { handbookThunks } from '@/entity/handbook'
import { staffActions } from '@/entity/staffingProject'
import { api, getFromLocalStorage } from '@/shared'
import { message } from 'antd'

function updateChilds(childs, targetId, newData) {
	return childs.map((child) => {
		if (child.externalId === targetId) {
			return { ...child, ...newData } // возвращаем обновленный объект
		} else if (child.childs) {
			return { ...child, childs: updateChilds(child.childs, targetId, newData) } // обновляем вложенные childs
		} else {
			return child // возвращаем оригинальный объект для всех остальных элементов
		}
	})
}

function createGroupWorkPlaces(childs, structureExternalId, newGroupWorkPlace) {
	let isUpdated = false // Флаг, чтобы отслеживать изменения

	const updatedChilds = childs.map((child) => {
		if (child.externalId === structureExternalId) {
			// Обновляем positionGroups, если нашли совпадение
			isUpdated = true
			const groupWorkPlacesUpdated = [...(child.positionGroups || []), newGroupWorkPlace]
			return { ...child, positionGroups: groupWorkPlacesUpdated }
		}
		if (child.childs) {
			// Рекурсивно обрабатываем дочерние элементы
			const updatedNestedChilds = createGroupWorkPlaces(child.childs, structureExternalId, newGroupWorkPlace)
			if (updatedNestedChilds !== child.childs) {
				isUpdated = true
				return { ...child, childs: updatedNestedChilds }
			}
		}
		return child
	})

	// Если изменений не произошло, возвращаем оригинальные childs
	return isUpdated ? updatedChilds : childs
}

function updateGroupWorkPlaces(data, structureExternalId, posGroupExternalId, editGroupWorkPlaceResponse) {
	function recursiveUpdate(childs) {
		for (let i = 0; i < childs.length; i++) {
			const child = childs[i]
			const newChild = { ...child }

			// Проверяем соответствие идентификатора структуры
			if (newChild.externalId === structureExternalId && newChild.positionGroups) {
				// Ищем и обновляем группу по posGroupExternalId
				const groupIndex = newChild.positionGroups.findIndex((group) => group.externalId === posGroupExternalId)

				if (groupIndex !== -1) {
					// Обновление данных группы на основе editGroupWorkPlaceResponse
					newChild.positionGroups[groupIndex] = {
						...newChild.positionGroups[groupIndex],
						...editGroupWorkPlaceResponse,
					}

					// Возвращаем обновленный список childs
					return [...childs.slice(0, i), newChild, ...childs.slice(i + 1)]
				}
			}

			if (newChild.childs) {
				const updatedChilds = recursiveUpdate(newChild.childs)
				if (updatedChilds !== newChild.childs) {
					// Значит обновление произошло в поддереве
					newChild.childs = updatedChilds

					return [...childs.slice(0, i), newChild, ...childs.slice(i + 1)]
				}
			}
		}

		return childs
	}

	return recursiveUpdate(data)
}

function removeGroupByExternalId(data, externalIdToRemove) {
	function recursiveRemove(childs) {
		if (!childs) return []
		const result = []

		for (const child of childs) {
			// Создаём копию child для предотвращения мутации
			const newChild = { ...child }

			if (newChild.positionGroups) {
				// Отфильтровываем positionGroups, чтобы удалить группы по externalId
				const filteredGroups = newChild.positionGroups.filter((group) => group.externalId !== externalIdToRemove)
				if (filteredGroups.length !== newChild.positionGroups.length) {
					// Найдена и удалена группа, возвращаем текущий результат
					newChild.positionGroups = filteredGroups
					result.push(newChild)
					return result.concat(childs.slice(childs.indexOf(child) + 1))
				}
				newChild.positionGroups = filteredGroups
			}

			if (newChild.childs) {
				// Рекурсивно обрабатываем childs
				const updatedChildren = recursiveRemove(newChild.childs)
				if (updatedChildren.length !== newChild.childs.length) {
					newChild.childs = updatedChildren
					result.push(newChild)
					return result.concat(childs.slice(childs.indexOf(child) + 1))
				}
				newChild.childs = updatedChildren
			}
			result.push(newChild)
		}

		return result
	}
	return recursiveRemove(data)
}

function updateEditChildWorkPlaces(childs, structureExternalId, positionGroupExternalId, newWorkPlace) {
	let updatedChilds = [...childs] // Создаем копию массива, чтобы работать с ней, не изменяя оригинальный массив.

	for (let i = 0; i < updatedChilds.length; i++) {
		let currentChild = updatedChilds[i]

		// Ищем соответствующего ребенка (child).
		if (currentChild.externalId === structureExternalId) {
			// Перебираем группы positionGroups
			let group = currentChild.positionGroups?.find((group) => group.externalId === positionGroupExternalId)

			if (group) {
				let index = group.workPlaces.findIndex((workPlace) => workPlace.externalId === newWorkPlace.externalId)

				if (index !== -1) {
					// Соответствующее рабочее место (workplace) найдено.
					group.workPlaces[index] = newWorkPlace // Обновляем его.
					return updatedChilds // Возвращаем результат, так как обновление уже завершено.
				}
			}
		}

		// Если у нашего дочернего элемента (child) есть свои дочерние элементы (childs), то ищем в них рекурсивно.
		if (currentChild.childs) {
			let result = updateEditChildWorkPlaces(
				currentChild.childs,
				structureExternalId,
				positionGroupExternalId,
				newWorkPlace
			)
			if (result !== null) {
				// Обновление проведено в дочернем элементе.
				return updatedChilds // Возвращаем результат, так как обновление уже завершено.
			}
		}
	}

	// Если выполнение дошло до этого момента, это значит, что рабочее место (workplace) не было обновлено.
	// Возвращаем null, чтобы указать это.
	return null
}

function deleteFromChildWorkPlaces(childs, structureExternalId, positionGroupExternalId, deleteWorkPlaceExternalId) {
	const updatedChilds = childs.map((child) => {
		// Если узел соответствует заданному structureExternalId
		if (child.externalId === structureExternalId) {
			// Обновляем группы должностей
			const updatedPositionGroups = child.positionGroups
				?.map((group) => {
					if (group.externalId === positionGroupExternalId) {
						// Удаляем рабочее место из группы
						const updatedWorkPlaces = group.workPlaces.filter(
							(workPlace) => workPlace.externalId !== deleteWorkPlaceExternalId
						)

						// Если после удаления рабочих мест не осталось, возвращаем null вместо группы
						if (updatedWorkPlaces.length === 0) {
							return null
						}

						// Возвращаем обновлённую группу
						return {
							...group,
							workPlaces: updatedWorkPlaces,
							staffUnitsCount: updatedWorkPlaces.length, // Пересчитываем количество рабочих мест
						}
					}
					return group // Остальные группы возвращаем без изменений
				})
				// Убираем группы, которые были помечены как null
				.filter((group) => group !== null)

			// Возвращаем обновлённый узел структуры
			return {
				...child,
				positionGroups: updatedPositionGroups,
			}
		}

		// Обрабатываем вложенные childs рекурсивно
		if (child.childs) {
			return {
				...child,
				childs: deleteFromChildWorkPlaces(
					child.childs,
					structureExternalId,
					positionGroupExternalId,
					deleteWorkPlaceExternalId
				),
			}
		}

		// Если узел не подходит, возвращаем его без изменений
		return child
	})

	return updatedChilds
}

export const getOptions = () => {
	return (dispatch, getState) => {
		const {
			staffType,
			staffStatus,
			staffEmpStatus,
			esutdRegErrors,
			positions,
			positionsByKnz,
			laborMobilityCenterCategory,
			establishedPostPositionCategoryRelation,
			districts,
		} = getState().handbook
		if (!positions.length) dispatch(handbookThunks.getPositions())
		if (!laborMobilityCenterCategory.length) dispatch(handbookThunks.getCenterCategory())
		if (!establishedPostPositionCategoryRelation.length) dispatch(handbookThunks.getCategoryRelation())
		if (!staffType.length) dispatch(handbookThunks.getStaffDeptType())
		if (!staffStatus.length) dispatch(handbookThunks.getStaffStatus())
		if (!staffEmpStatus.length) dispatch(handbookThunks.getStaffEmpStatus())
		if (!esutdRegErrors.length) dispatch(handbookThunks.esutdRegErrors())
		if (!positionsByKnz.length) dispatch(handbookThunks.getPositionsByKnz())
		if (!districts.length) dispatch(handbookThunks.getDistricts())
	}
}

export const getProject = () => {
	return async (dispatch) => {
		try {
			dispatch(staffActions.toggleLoading(true))
			let body = {}
			const staffProject = await api.staff.getProject(body)
			dispatch(staffActions.setStaffProject(staffProject))
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleSuccess(true))
		} catch (error) {
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleError(error))
			message.error(
				'Ошибка получения данных:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffActions.toggleSuccess(false))
		}
	}
}

export const getStatusProject = (payload) => {
	return async (dispatch) => {
		try {
			let body = {
				params: {
					...payload,
				},
			}
			dispatch(staffActions.toggleLoading(true))
			const statusProject = await api.staff.getStatusProject(body)
			dispatch(staffActions.setStatus(statusProject.statusCode))
			if (statusProject.statusCode === '3') {
				dispatch(staffActions.setStaffProject(statusProject.staffOrgDto))
			}
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleSuccess(true))
		} catch (error) {
			dispatch(staffActions.toggleError(error))
			message.error(
				'Ошибка получения статуса:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffActions.toggleSuccess(false))
		}
	}
}

export const createProject = (project) => {
	return async (dispatch) => {
		try {
			let body = {
				data: project,
			}

			dispatch(staffActions.toggleLoading(true))
			const createdProject = await api.staff.createProject(body)
			dispatch(staffActions.setStaffProject(createdProject))
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleSuccess(true))
			message.success('Проект штатного расписания успешно создан!', 5)
		} catch (error) {
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleError(error))
			message.error(
				'Ошибка сохранения данных:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffActions.toggleSuccess(false))
		}
	}
}

export const editProject = (project) => {
	return async (dispatch, getState) => {
		try {
			let body = {
				data: project,
			}

			dispatch(staffActions.toggleLoading(true))

			const response = await api.staff.editProject(body)

			if (Object.keys(response).length !== 0) {
				throw new Error(response)
			}

			const { staff } = getState()

			const updatedProject = {
				...staff.staff.data,
				note: project.note,
				orderNumber: project.orderNumber,
				orderDate: project.orderDate,
				// Заменяем объект dept из project.dept в первом элементе childs
				childs:
					staff.staff.data.childs.length > 0
						? [
								{
									...staff.staff.data.childs[0],
									dept: project.dept,
								},
								...staff.staff.data.childs.slice(1),
						  ]
						: [],
			}

			// Используем updatedProject для обновления состояния Redux
			dispatch(staffActions.setStaffProject(updatedProject))

			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleSuccess(true))
			message.success('Проект штатного расписания успешно изменён!', 5)
		} catch (error) {
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleError(error))
			message.error(
				'Ошибка изменения данных: ' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffActions.toggleSuccess(false))
		}
	}
}

export const delProject = () => {
	return async (dispatch) => {
		try {
			let body = {}
			dispatch(staffActions.toggleLoading(true))
			const deletedProject = await api.staff.removeProject(body)
			dispatch(staffActions.setStaffProject(deletedProject))
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleSuccess(true))
			message.success('Проект штатного расписания успешно удален!', 5)
		} catch (error) {
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleError(error))
			message.error(
				'Ошибка удаления данных:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffActions.toggleSuccess(false))
		}
	}
}

export const addStructure = (structure) => {
	return async (dispatch) => {
		try {
			let body = {
				data: structure,
			}
			dispatch(staffActions.toggleLoading(true))
			const addedStructure = await api.staff.addStructure(body)
			dispatch(staffActions.setStaffProject(addedStructure))
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleSuccess(true))
			message.success('Структура успешно добавлена!', 5)
		} catch (error) {
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleError(error))
			message.error(
				'Ошибка добавления данных:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffActions.toggleSuccess(false))
		}
	}
}

export const editStructure = (structure) => {
	return async (dispatch, getState) => {
		try {
			let body = {
				data: structure,
			}
			dispatch(staffActions.toggleLoading(true))
			const editStructureResponse = await api.staff.editStructure(body)
			// Проверка на ошибку в editStructure
			if (Object.keys(editStructureResponse).length !== 0) {
				throw new Error(editStructureResponse)
			}
			const { staff } = getState()
			// функция обновления проекта
			const updatedProject = {
				...staff.staff.data,
				childs: staff.staff.data.childs.map((child) => ({
					...child,
					childs: updateChilds(child.childs, structure.row.externalId, structure.row),
				})),
			}
			dispatch(staffActions.setStaffProject(updatedProject))
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleSuccess(true))
			message.success('Структура успешно изменена!', 5)
		} catch (error) {
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleError(error))
			message.error(
				'Ошибка изменения данных:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffActions.toggleSuccess(false))
		}
	}
}

export const delStructure = (deleteStructure) => {
	return async (dispatch) => {
		try {
			let body = {
				data: deleteStructure,
			}
			dispatch(staffActions.toggleLoading(true))
			const staffProject = await api.staff.removeStructure(body)
			dispatch(staffActions.setStaffProject(staffProject))
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleSuccess(true))
			message.success('Структура успешно удалена!', 5)
		} catch (error) {
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleError(error))
			message.error(
				'Ошибка удаления данных:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffActions.toggleSuccess(false))
		}
	}
}

export const createGroupWorkPlace = (groupWorkPlace) => {
	return async (dispatch, getState) => {
		try {
			let body = {
				data: groupWorkPlace,
			}
			dispatch(staffActions.toggleLoading(true))
			const addedGroupWorkPlace = await api.staff.createGroupWorkPlace(body)
			const { staff } = getState()
			const updatedProject = {
				...staff.staff.data,
				childs: createGroupWorkPlaces(staff.staff.data.childs, groupWorkPlace.structureExternalId, addedGroupWorkPlace),
			}
			dispatch(staffActions.setStaffProject(updatedProject))
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleSuccess(true))
			message.success('Группа профессий успешно добавлена!', 5)
		} catch (error) {
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleError(error))
			message.error(
				'Ошибка добавления данных:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffActions.toggleSuccess(false))
		}
	}
}

export const editGroupWorkPlace = (groupWorkPlace, activeRowId) => {
	return async (dispatch, getState) => {
		try {
			const activeStaffBranch = await getFromLocalStorage('activeStaffBranch')

			let body = {
				params: {
					currentStaffBranchBin: activeStaffBranch,
				},
				data: groupWorkPlace,
			}
			dispatch(staffActions.toggleLoading(true))
			const editGroupWorkPlaceResponse = await api.staff.editGroupWorkPlace(body)
			const { staff } = getState()
			// функция обновления проекта
			const updatedProject = {
				...staff.staff.data,
				childs: updateGroupWorkPlaces(
					staff.staff.data.childs,
					activeRowId,
					groupWorkPlace.posGroupExternalId,
					editGroupWorkPlaceResponse
				),
			}
			dispatch(staffActions.setStaffProject(updatedProject))
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleSuccess(true))
			message.success('Должность успешно изменена!', 5)
		} catch (error) {
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleError(error))
			message.error(
				'Ошибка изменения данных:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffActions.toggleSuccess(false))
		}
	}
}

export const delGroupWorkPlace = (staffPositionGroupExternalId) => {
	return async (dispatch, getState) => {
		try {
			const activeStaffBranch = await getFromLocalStorage('activeStaffBranch')

			let params = {
				staffPositionGroupExternalId: staffPositionGroupExternalId,
				currentStaffBranchBin: activeStaffBranch,
			}

			dispatch(staffActions.toggleLoading(true))
			const response = await api.staff.deleteGroupWorkPlace(params)

			const { staff } = getState()

			const updatedProject = {
				...staff.staff.data,
				// Здесь мы используем нашу функцию удаления
				childs: removeGroupByExternalId(staff.staff.data.childs, staffPositionGroupExternalId),
			}

			dispatch(staffActions.setStaffProject(updatedProject))
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleSuccess(true))
			message.success('Группа должностей успешно удалена!', 5)
		} catch (error) {
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleError(error))
			message.error(
				'Ошибка удаления данных:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffActions.toggleSuccess(false))
		}
	}
}

export const editWorkPlace = (workplace) => {
	return async (dispatch, getState) => {
		try {
			let body = {
				data: workplace,
			}
			dispatch(staffActions.toggleLoading(true))
			const editWorkplaceResponse = await api.staff.editWorkPlace(body)
			// Проверка на ошибку в editWorkPlace
			if (Object.keys(editWorkplaceResponse).length !== 0) {
				throw new Error(editWorkplaceResponse)
			}
			const { staff } = getState()

			// функция обновления проекта
			const updatedProject = {
				...staff.staff.data,
				childs: updateEditChildWorkPlaces(
					staff.staff.data.childs,
					workplace.structureExternalId,
					workplace.positionGroupExternalId,
					workplace.row
				),
			}
			dispatch(staffActions.setStaffProject(updatedProject))
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleSuccess(true))
			message.success('Должность успешно изменена!', 5)
		} catch (error) {
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleError(error))
			message.error(
				'Ошибка изменения данных:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffActions.toggleSuccess(false))
		}
	}
}

export const delWorkPlace = (deleteWorkPlace) => {
	return async (dispatch, getState) => {
		try {
			const body = {
				data: deleteWorkPlace,
			}

			dispatch(staffActions.toggleLoading(true))
			const response = await api.staff.removeWorkPlace(body)

			if (Object.keys(response).length !== 0) {
				throw new Error(response)
			}

			const { staff } = getState()

			// Пересчёт структуры после удаления рабочего места
			const updatedChilds = deleteFromChildWorkPlaces(
				staff.staff.data.childs,
				deleteWorkPlace.structureExternalId,
				deleteWorkPlace.positionGroupExternalId,
				deleteWorkPlace.delExternalIds[0]
			)

			// Создаём обновлённый объект данных
			const updatedProject = {
				...staff.staff.data,
				childs: updatedChilds, // Структура с пересчитанными значениями
			}

			dispatch(staffActions.setStaffProject(updatedProject))
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleSuccess(true))
			message.success('Рабочее место успешно удалено!', 5)
		} catch (error) {
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleError(error))
			message.error('Ошибка при удалении. ' + (error?.response?.data?.message || 'Неизвестная ошибка'), 10)
		} finally {
			dispatch(staffActions.toggleSuccess(false))
		}
	}
}

export const getXML = () => {
	return async (dispatch) => {
		try {
			dispatch(staffActions.toggleLoading(true))
			let body = {}
			const xmlData = await api.staff.getXml(body)
			dispatch(staffActions.setStaffXml(xmlData))
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleSuccess(true))
		} catch (error) {
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleError(error))
			message.error(
				'Ошибка получения данных для подписи:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffActions.toggleSuccess(false))
		}
	}
}

export const sendSignedStaff = (data) => {
	return async (dispatch) => {
		try {
			dispatch(staffActions.toggleLoading(true))
			let body = {
				data: { data },
			}
			const signedStaff = await api.staff.sendSignedStaff(body)
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleSuccess(true))
			message.success('Подписанное штатное расписания успешно отправлено!', 5)
		} catch (error) {
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleError(error))
			message.error(
				'Ошибка отправки данных:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffActions.toggleSuccess(false))
			dispatch(staffActions.setStaffXml(null))
		}
	}
}
