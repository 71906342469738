import { request } from '@/shared/api/request'

export const createGroupWorkPlace = ({ data, params }) => {
	return request({ method: 'POST', url: 'staff/positionGroup/create', data, params })
}

export const editGroupWorkPlace = ({ data, params }) => {
	return request({ method: 'POST', url: 'staff/positionGroup/edit', data, params })
}

export const deleteGroupWorkPlace = (params) => {
	return request({
		method: 'POST',
		url: 'staff/positionGroup/delete',
		params,
	})
}
