import React, { useEffect, useMemo, useState } from 'react'
import { Button, Title } from '@/ui'
import { useTranslation } from 'react-i18next'
import styles from './WorkplaceCard.module.scss'
import { Add } from '@app/icons'
import { WorkplaceInformation } from 'features/staffing/StaffingPositionForm/view/WorkplaceInformation'
import { TableViewWorkplace } from '@features/staffing/StaffingPositionForm/view/table/tableViewWorkplace'
import { StaffingPositionForm } from '@features/staffing/StaffingPositionForm'
import { ModalSolutionOutPosition } from './table/ModalSolutionOutPosition/ModalSolutionOutPosition'
import { staffingActions, staffingSelectors, staffingThunks } from '@/entity'
import { useDispatch, useSelector } from 'react-redux'
import { InfoWarning } from '@/components/infoWarning'
import { useDigitalSign } from '@/shared'
import { ViewEmployeeInformation } from '@features/staffing/StaffingPositionForm/view/table/ViewEmployeeInformation/ViewEmployeeInformation'

export const WorkplaceCard = ({ workPlaceRowSelect, onClose, onCreate, deactivateStaff, groupWorkPlace }) => {
	const {
		t,
		i18n: { language },
	} = useTranslation()
	const dispatch = useDispatch()
	const { successPayload, onSign, failedPayload, onClearPayload } = useDigitalSign()
	const optionLang = useMemo(() => (language === 'rus' ? 'nameRu' : 'nameKz'), [language])
	const [editStaffingPosition, setEditStaffingPosition] = useState(null)
	const employeeeDelXML = useSelector(staffingSelectors.employeeeDelXML)
	const { staffingGrants } = useSelector((state) => ({
		staffingGrants: state.administration.staffingGrants,
	}))
	const onClear = () => {
		onClearPayload()
		dispatch(staffingActions.setDelEmployeeXml(null))
	}

	useEffect(() => {
		failedPayload && onClear()
	}, [failedPayload])

	useEffect(() => employeeeDelXML && onSign(employeeeDelXML), [employeeeDelXML])

	useEffect(() => {
		if (successPayload) {
			if (employeeeDelXML) {
				dispatch(staffingThunks.sendDelWorkPlaceEsutd(editStaffingPosition.externalId, successPayload))
			}
		}
	}, [successPayload])

	const [actions, setActions] = useState({
		view: false,
		edit: false,
		remove: false,
	})

	const onToggleAction = (action, value) => {
		setActions((prevState) => {
			return { ...prevState, [action]: value }
		})
	}

	const handleProcess = (data) => {
		if (editStaffingPosition.statusCode === 'SENT') {
			dispatch(staffingThunks.deleteWorkPlaceEsutd(editStaffingPosition.externalId))
		} else {
			dispatch(staffingThunks.deleteWorkPlace(data, workPlaceRowSelect.externalId))
		}
		onToggleAction('remove', false)
	}
	const handleClose = (e) => {
		onToggleAction('remove', false)
		onToggleAction('edit', false)
	}

	const closeViewEmployee = () => {
		onToggleAction('view', false)
	}

	return (
		<div>
			{actions.view ? (
				<ViewEmployeeInformation
					position={editStaffingPosition}
					handleCancel={closeViewEmployee}
				></ViewEmployeeInformation>
			) : null}
			{actions.remove ? (
				<ModalSolutionOutPosition
					onSubmit={handleProcess}
					handleCancel={handleClose}
					editStaffingPosition={editStaffingPosition}
				></ModalSolutionOutPosition>
			) : null}
			{actions.edit ? (
				<StaffingPositionForm
					initialValues={editStaffingPosition}
					onClose={handleClose}
					actions={actions}
					setActions={setActions}
					workPlaceRowSelect={workPlaceRowSelect}
				></StaffingPositionForm>
			) : null}
			<Title color="black">{workPlaceRowSelect.establishedPost[optionLang]}</Title>
			{deactivateStaff ? (
				<InfoWarning />
			) : (
				<div className={styles.actions}>
					{staffingGrants.data?.stRegistration && (
						<Button
							buttonClassName={styles.button}
							textColor="green"
							borderColor="green"
							transparent
							border
							onClick={onCreate}
						>
							<Add className={styles.iconGreen} />
							{t('add_position')}
						</Button>
					)}
				</div>
			)}
			<div className={styles.informationSections}>
				<section>
					<WorkplaceInformation workplace={workPlaceRowSelect} groupWorkPlace={groupWorkPlace}></WorkplaceInformation>
				</section>
				<section className={styles.informationSections}>
					<Title>{t('workplace.accept_position')}</Title>
					<TableViewWorkplace
						setEditStaffingPosition={setEditStaffingPosition}
						setActions={setActions}
						deactivateStaff={deactivateStaff}
					></TableViewWorkplace>
				</section>
			</div>
			<div className={styles.close}>
				<Button onClick={onClose}>{t('close')}</Button>
			</div>
		</div>
	)
}
